export const Chat = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M11.9999 4.94446C7.58173 4.94446 4 7.7302 4 11.1666C4 13.1079 5.14399 14.8412 6.93507 15.9821C6.70219 17.0083 6.15953 18.5305 4.88888 20.0554C7.44796 19.6287 9.08394 18.3838 10.0959 17.2039C10.7075 17.3203 11.3426 17.3887 11.9999 17.3887C16.4181 17.3887 19.9998 14.603 19.9998 11.1666C19.9998 7.7302 16.4181 4.94446 11.9999 4.94446Z"
            fill="#BDDDF4"
        />
        <path
            d="M12 12.0555C12.4909 12.0555 12.8888 11.6576 12.8888 11.1666C12.8888 10.6757 12.4909 10.2778 12 10.2778C11.509 10.2778 11.1111 10.6757 11.1111 11.1666C11.1111 11.6576 11.509 12.0555 12 12.0555Z"
            fill="#2A6797"
        />
        <path
            d="M15.5555 12.0555C16.0464 12.0555 16.4444 11.6576 16.4444 11.1666C16.4444 10.6757 16.0464 10.2778 15.5555 10.2778C15.0646 10.2778 14.6666 10.6757 14.6666 11.1666C14.6666 11.6576 15.0646 12.0555 15.5555 12.0555Z"
            fill="#2A6797"
        />
        <path
            d="M8.44442 12.0555C8.93533 12.0555 9.3333 11.6576 9.3333 11.1666C9.3333 10.6757 8.93533 10.2778 8.44442 10.2778C7.95351 10.2778 7.55554 10.6757 7.55554 11.1666C7.55554 11.6576 7.95351 12.0555 8.44442 12.0555Z"
            fill="#2A6797"
        />
    </svg>
);

export const Help = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M16.2128 14.9478L14.9478 16.2128L12.7335 13.9986L13.9986 12.7335L16.2128 14.9478Z"
            fill="#9AAAB4"
        />
        <path
            d="M19.4758 16.9457L16.8459 14.3159C16.4966 13.9665 15.9303 13.9665 15.5809 14.3159L14.3159 15.5809C13.9665 15.9303 13.9665 16.4966 14.3159 16.8459L16.9457 19.4758C17.6444 20.175 18.778 20.175 19.4767 19.4758C20.1745 18.7775 20.1741 17.6444 19.4758 16.9457Z"
            fill="#66757F"
        />
        <path
            d="M10.0389 16.0779C13.3742 16.0779 16.0779 13.3742 16.0779 10.0389C16.0779 6.70373 13.3742 4 10.0389 4C6.70373 4 4 6.70373 4 10.0389C4 13.3742 6.70373 16.0779 10.0389 16.0779Z"
            fill="#8899A6"
        />
        <path
            d="M10.0387 14.2883C12.3857 14.2883 14.2883 12.3857 14.2883 10.0387C14.2883 7.69169 12.3857 5.78906 10.0387 5.78906C7.69169 5.78906 5.78906 7.69169 5.78906 10.0387C5.78906 12.3857 7.69169 14.2883 10.0387 14.2883Z"
            fill="#BBDDF5"
        />
    </svg>
);

export const Academy = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M14.8176 10.0139H9.18288C9.18288 10.0139 6.83508 12.7375 6.83508 14.5532C6.83508 16.3689 12.0002 19.5464 12.0002 19.5464C12.0002 19.5464 17.1654 16.3689 17.1654 14.5532C17.1654 12.7375 14.8176 10.0139 14.8176 10.0139Z"
            fill="#31373D"
        />
        <path
            d="M12.7698 4.23967C12.3468 3.92011 11.6537 3.92011 11.2306 4.23967L4.3173 9.45801C3.89423 9.77758 3.89423 10.3005 4.3173 10.6201L11.2302 15.8379C11.6537 16.1575 12.3463 16.1575 12.7694 15.8379L19.6822 10.6201C20.1058 10.3005 20.1058 9.77758 19.6822 9.45801L12.7698 4.23967Z"
            fill="#292F33"
        />
        <path
            d="M12.7698 4.23967C12.3468 3.92011 11.6537 3.92011 11.2306 4.23967L4.3173 9.45801C3.89423 9.77758 3.89423 10.3005 4.3173 10.6201L11.2302 15.8379C11.6532 16.1575 12.3463 16.1575 12.7694 15.8379L19.6827 10.6201C20.1058 10.3005 20.1058 9.77758 19.6827 9.45801L12.7698 4.23967Z"
            fill="#394146"
        />
        <path
            d="M7.3046 15.007C7.3046 15.007 6.36548 15.9148 6.36548 16.3687V19.0923C6.36548 19.0923 6.36548 20.0001 7.3046 20.0001C8.24372 20.0001 8.24372 19.0923 8.24372 19.0923V16.3687C8.24372 15.9148 7.3046 15.007 7.3046 15.007Z"
            fill="#FCAB40"
        />
        <path
            d="M7.30455 16.8225C8.08254 16.8225 8.71323 16.2128 8.71323 15.4607C8.71323 14.7086 8.08254 14.0989 7.30455 14.0989C6.52656 14.0989 5.89587 14.7086 5.89587 15.4607C5.89587 16.2128 6.52656 16.8225 7.30455 16.8225Z"
            fill="#FDD888"
        />
        <path
            d="M7.3051 15.9141C7.04591 15.9141 6.83554 15.7112 6.83554 15.4602V13.6636C6.81582 13.3758 6.92335 12.7394 7.45395 12.3544L11.6903 8.76389C11.8857 8.59821 12.1824 8.61727 12.3529 8.80611C12.5238 8.99448 12.5041 9.28137 12.3092 9.4466L8.0456 13.0589C7.76199 13.2659 7.77419 13.6363 7.77419 13.64L7.77513 15.4602C7.77513 15.7112 7.56477 15.9141 7.3051 15.9141Z"
            fill="#FCAB40"
        />
        <path
            d="M11.9999 10.9215C12.7779 10.9215 13.4085 10.3118 13.4085 9.55972C13.4085 8.80763 12.7779 8.19794 11.9999 8.19794C11.2219 8.19794 10.5912 8.80763 10.5912 9.55972C10.5912 10.3118 11.2219 10.9215 11.9999 10.9215Z"
            fill="#31373D"
        />
    </svg>
);

export const FeatureRequest = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M17.3594 8.63849C17.3594 11.9632 14.7776 12.4796 14.7776 15.5782C14.7776 17.1778 13.1651 17.3126 11.9377 17.3126C10.8777 17.3126 8.53708 16.9104 8.53708 15.5771C8.53708 12.4801 5.99976 11.9638 5.99976 8.63849C5.99976 5.52441 8.72864 3 11.7224 3C14.7172 3 17.3594 5.52441 17.3594 8.63849Z"
            fill="#FFD983"
        />
        <path
            d="M13.8311 19.7089C13.8311 20.1365 12.6775 20.9998 11.6794 20.9998C10.6813 20.9998 9.52783 20.1365 9.52783 19.7089C9.52783 19.2814 10.6808 19.4508 11.6794 19.4508C12.6775 19.4508 13.8311 19.2814 13.8311 19.7089Z"
            fill="#CCD6DD"
        />
        <path
            d="M14.1101 8.24248C13.9082 8.04059 13.5819 8.04059 13.38 8.24248L11.6796 9.94281L9.97931 8.24248C9.77742 8.04059 9.45109 8.04059 9.2492 8.24248C9.04731 8.44437 9.04731 8.7707 9.2492 8.9726L11.1633 10.8867V16.3527C11.1633 16.6383 11.3946 16.8691 11.6796 16.8691C11.9647 16.8691 12.196 16.6383 12.196 16.3527V10.8867L14.1101 8.9726C14.312 8.7707 14.312 8.44437 14.1101 8.24248Z"
            fill="#FFCC4D"
        />
        <path
            d="M14.7778 18.935C14.7778 19.5051 14.3152 19.9677 13.7451 19.9677H9.61436C9.04431 19.9677 8.58167 19.5051 8.58167 18.935V15.837H14.7778V18.935Z"
            fill="#99AAB5"
        />
        <path
            d="M8.58056 19.4506C8.33271 19.4506 8.11378 19.2714 8.07195 19.0189C8.02497 18.738 8.21498 18.4716 8.49639 18.4251L14.6925 17.3924C14.9734 17.3418 15.2399 17.5355 15.2863 17.8169C15.3333 18.0978 15.1433 18.3642 14.8619 18.4107L8.66575 19.4434C8.63735 19.4485 8.60844 19.4506 8.58056 19.4506ZM8.58056 17.3852C8.33271 17.3852 8.11378 17.206 8.07195 16.9536C8.02497 16.6727 8.21498 16.4062 8.49639 16.3598L14.6925 15.3271C14.9734 15.277 15.2399 15.4701 15.2863 15.7515C15.3333 16.0324 15.1433 16.2988 14.8619 16.3453L8.66575 17.378C8.63735 17.3832 8.60844 17.3852 8.58056 17.3852Z"
            fill="#CCD6DD"
        />
    </svg>
);

export const Training = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M19.3389 15.8422L17.7414 14.2446C16.8594 13.3626 15.4291 13.3626 14.5476 14.2446C14.219 14.5727 14.0143 14.9771 13.9302 15.3998C11.2958 14.9072 8.73356 12.3555 8.57408 10.0747C9.00683 9.99431 9.4213 9.78821 9.7558 9.45325C10.6378 8.5713 10.6378 7.14098 9.7558 6.25903L8.15869 4.66146C7.27674 3.77951 5.84642 3.77951 4.96447 4.66146C0.172681 9.45325 14.5471 23.8277 19.3389 19.0359C20.2204 18.1544 20.2204 16.7241 19.3389 15.8422Z"
            fill="#31373D"
        />
    </svg>
);

const icons = {
    help: Help,
    chat: Chat,
    academy: Academy,
    'feature-request': FeatureRequest,
    training: Training,
};

export default icons;
