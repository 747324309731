import { i18n } from 'next-i18next';

import { showToast } from '@/app/toasts/utils/showToast';
import { reportMessage } from '@/utils/sentry';

export const handleIntercomLoadError = () => {
    reportMessage({ message: 'Intercom not found', source: 'intercom', severityLevel: 'info' });

    showToast({
        message: i18n?.t('common:intercom-fail-message') || 'Failed to load Intercom',
        description:
            i18n?.t('common:intercom-fail-description') ||
            "We couldn't load our chat. Please check if you have an ad blocker installed and try to disable it.",
        type: 'warning',
        actionText: i18n?.t('common:contact-us') || 'Contact us',
        onActionClick: () => {
            window.location.href = 'mailto:support@perspective.co';
        },
    });
};
