import get from 'lodash/get';

import { getUser } from '@/app/user/models/user';
import { useAppSelector } from '@/core/redux/hooks';

const Header = () => {
    const user = useAppSelector(getUser);

    const name = get(user, 'attributes.fullName', 'John Doe');
    const email = get(user, 'attributes.email', 'john@doe.com');

    return (
        <div className="mb-2 border-b px-6 py-4 text-sm">
            <p className="truncate font-semibold text-gray-800">{name}</p>
            <p className="mt-1 truncate text-gray-500">{email}</p>
        </div>
    );
};

export default Header;
