import Gravatar from 'react-gravatar';

import { cn } from '@/utils/cn';

export interface Props {
    name: string;
    email?: string;
    size?: number;
    isCrmUser?: boolean;
    fontSizeRatio?: number;
    className?: string;
    charsNumber?: number;
}

const COLOR_MAP = {
    primaryBlue: {
        bgColor: '3c82f6',
        textColor: 'ffffff',
    },
    secondaryGray: {
        bgColor: 'd9dce3',
        textColor: '4b5563',
    },
};

export const UserGravatar = ({
    name,
    email,
    isCrmUser,
    className,
    size = 32,
    charsNumber = 2,
    fontSizeRatio = 0.35,
}: Props) => {
    const avatarClassName = cn('rounded-full', className);
    const colors = COLOR_MAP[isCrmUser ? 'secondaryGray' : 'primaryBlue'];

    return (
        <Gravatar
            email={email ?? 'placeholder@perspective.co'}
            default={
                email
                    ? `https://eu.ui-avatars.com/api/${name.replace(' ', '+')}/128/${
                          colors.bgColor
                      }/${colors.textColor}/${charsNumber}/${fontSizeRatio}/false/true/true` // https://eu.ui-avatars.com/
                    : 'mm'
            }
            size={size}
            className={avatarClassName}
        />
    );
};

export default UserGravatar;
