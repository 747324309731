import {
    GlobeAltIcon,
    InformationCircleIcon,
    UserIcon,
    ArrowRightStartOnRectangleIcon,
} from '@heroicons/react/24/outline';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { CapabilitiesTarget, useUserCapabilities } from '@/hooks/useUserCapabilities';

import { LinkEntry } from './LinkEntry';
import { INTERCOM_HELP_CENTER_URL, NAME } from '../../../constants';

interface Props {
    isWorkspaceUser: boolean;
}

const Body = ({ isWorkspaceUser }: Props) => {
    const { t } = useTranslation(NAME);
    const router = useRouter();

    const { query } = router;
    const isReactivation = !!query.reactivation;
    const { canRead: canReadDomains } = useUserCapabilities(CapabilitiesTarget.Domains);

    const defaultSettings = (
        <>
            {!isReactivation && (
                <>
                    <LinkEntry link="/settings" text={t('settings')} icon={UserIcon} />
                    {canReadDomains && (
                        <LinkEntry link="/domains" text={t('domains')} icon={GlobeAltIcon} />
                    )}
                </>
            )}
            <LinkEntry
                link={INTERCOM_HELP_CENTER_URL}
                isExternal={true}
                text={t('help')}
                icon={InformationCircleIcon}
            />
        </>
    );

    const workspaceUserSettings = !isReactivation && (
        <>
            <LinkEntry link="/settings" text={t('settings')} icon={UserIcon} />
            <LinkEntry
                link="/logout"
                text={t('logout')}
                icon={ArrowRightStartOnRectangleIcon}
                isDanger
            />
        </>
    );

    return (
        <div className="flex flex-col gap-1 px-2">
            {isWorkspaceUser ? workspaceUserSettings : defaultSettings}
        </div>
    );
};

export default Body;
