import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import Link from 'next/link';

import { cn } from '@/utils/cn';

import type { ComponentProps, ReactNode } from 'react';

export interface Props {
    link: string;
    text: string;
    isExternal?: boolean;
    isDanger?: boolean;
    icon: (props: ComponentProps<'svg'>) => ReactNode;
}

export const LinkEntry = ({ isExternal, isDanger, link, text, icon: Icon }: Props) => {
    const linkClassName = cn(
        'flex cursor-pointer items-center rounded-md px-3 py-2 text-sm text-gray-500 outline-none hover:bg-gray-100 hover:text-gray-800',
        {
            'text-red-500 hover:text-red-600': isDanger,
        },
    );

    return (
        <DropdownMenu.Item asChild>
            {isExternal ? (
                <a href={link} rel="noopener noreferrer" target="_blank" className={linkClassName}>
                    <Icon className="size-4 stroke-2" />
                    <span className="ml-3">{text}</span>
                </a>
            ) : (
                <Link href={link} className={linkClassName}>
                    <Icon className="size-4 stroke-2" />
                    <span className="ml-3">{text}</span>
                </Link>
            )}
        </DropdownMenu.Item>
    );
};
