import { NAME } from '@/app/support/constants';

import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

import { handleIntercomLoadError } from '@/utils/intercom';

import icons from './icons';
import { Item } from './Item';

export const Menu = () => {
    const { t } = useTranslation(NAME);
    const { locale } = useRouter();

    const items = useMemo(() => {
        const handleContactUsClick = async () => {
            if (window.Intercom && typeof window.Intercom === 'function') {
                window.Intercom('show');
            } else {
                handleIntercomLoadError();
            }
        };

        return [
            {
                title: t('help'),
                icon: icons['help'],
                url: t('urls.help'),
                isExternal: true,
            },
            {
                title: t('academy'),
                icon: icons['academy'],
                url: '/achievements',
            },
            {
                title: t('feature-request'),
                icon: icons['feature-request'],
                btnId: `feature-request-btn-${locale}`,
            },
            {
                title: t('contact-us'),
                icon: icons['chat'],
                onClick: handleContactUsClick,
                btnId: `support-bubble-contact-us-btn-${locale}`,
            },
            {
                title: t('register-for-training'),
                icon: icons['training'],
                url: t('urls.training'),
                isExternal: true,
            },
        ];
    }, [t, locale]);

    return (
        <ul>
            {items.map((item) => (
                <Item
                    key={item.title}
                    title={item.title}
                    btnId={item.btnId}
                    onClick={item.onClick}
                    icon={item.icon}
                    url={item.url}
                    isExternal={item.isExternal}
                />
            ))}
        </ul>
    );
};

export default Menu;
